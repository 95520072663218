import React from 'react'
import { graphql } from "gatsby"
import Layout from '../components/layout'
import Seo from '../components/seo'
import { useTranslation } from 'gatsby-plugin-react-i18next';

import makromusicProductImg from '../images/makromusic-product.png';
import makrosocialProductImg from '../images/makrosocial-product.png';
import forArtistsProductImg from '../images/for-artists-product.png';

const Products = () => {
  const {t} = useTranslation()

  return (
    <Layout>
      <Seo title={t("productsTitle")} />
      <div className="container pt-nav-ghost">
        <h1 className="title-md my-nav">{t("productsTitle")}</h1>
        <div className="products-container">
          <a href="https://makromusic.com" target="_blank" rel="noreferrer" className="w-1/2 ph-full">
            <div className="product-card">
              <img className="w-full" alt="makromusic" src={makromusicProductImg} />
              <div>
                <p className="product-card-title">makromusic</p>
                <p className="product-card-desc">{t("productMakromusicDesc")}</p>
              </div>
            </div>
          </a>
          <a href="https://artists.makromusic.com" target="_blank" rel="noreferrer" className="w-1/2 ph-full">
            <div className="product-card">
              <img className="w-full" alt="makromusic for Artists" src={forArtistsProductImg} />
              <div>
                <p className="product-card-title">makromusic for Artists</p>
                <p className="product-card-desc">{t("productForArtistsDesc")}</p>
              </div>
            </div>
          </a>
          <a href="https://makrosocial.xyz" target="_blank" rel="noreferrer" className="w-1/2 ph-full">
            <div className="product-card">
              <img className="w-full" alt="makrosocial" src={makrosocialProductImg} />
              <div>
                <p className="product-card-title">makrosocial</p>
                <p className="product-card-desc">{t("productMakrosocialDesc")}</p>
              </div>
            </div>
          </a>
        </div>
      </div>
    </Layout>
  )
}

export default Products

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`